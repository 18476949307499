<template>
  <div class="background">
    <Invites />
  </div>
</template>

<script>
import Invites from "@/components/elements/dashboard/invites/Invites";

export default {
  name: "DocumentManagementInvites",

  components: {
    Invites,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
