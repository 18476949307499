<template>
  <div>
    <v-card class="pa-4 mt-5 pa-lg-7" elevation="1" rounded="lg">
      <v-card flat>
        <v-data-table
          :headers="headers"
          :items="invites"
          sort-by="id"
          :sort-desc="true"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="invitesPerPage"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Invites</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="500px">
                <template
                  v-if="$can('invite_create')"
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Invite
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>

                    <v-spacer />

                    <div v-if="$can('invite_company') && editedIndex == -1">
                      <v-switch v-model="useCompanyName">
                        <template v-slot:label>
                          <div v-if="useCompanyName">Company</div>
                          <div v-else>User</div>
                        </template>
                      </v-switch>
                    </div>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" lazy-validation>
                        <v-row>
                          <!-- Company Name -->
                          <v-col
                            v-if="
                              $can('invite_company') &&
                              editedIndex == -1 &&
                              useCompanyName
                            "
                            cols="12"
                          >
                            <v-text-field
                              v-model="companyName"
                              :rules="companyNameRules"
                              label="Company Name"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Company Name <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Email -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedInvite.email"
                              :rules="emailRules"
                              label="Email"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Email <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="btn btn-primary" @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this invite?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="btn btn-primary" @click="deleteInviteConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          
          <!-- Copy Url -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.url="{ item }">
            <v-btn  @click="copyUrl(item)" color="primary" dark>
              Copy Url
            </v-btn>
          </template>

          <!-- created_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.created_at="{ item }">
            {{ $helpers.datetime(item.created_at) }}
          </template>

          <!-- actions -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="$can('invite_update')"
              small
              class="mr-2"
              @click="editInvite(item)"
              color="primary"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="$can('invite_delete')"
              small
              @click="deleteInvite(item)"
              color="red"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary"> No Data Available </v-btn>
          </template>
        </v-data-table>

        <v-row class="text-center px-4 align-center mt-4" wrap>
          <v-col class="text-truncate" cols="12" md="2">
            Total {{ totalRecords }} records
          </v-col>

          <v-col cols="12" md="6">
            <v-pagination v-model="page" :length="pageCount"> </v-pagination>
          </v-col>

          <v-col cols="6" md="3">
            <v-select
              v-model="perPageChoice"
              label="Invites per page"
              item-text="text"
              item-value="value"
              :items="perPageChoices"
              dense
              outlined
              hide-details
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card>

      <!-- Overlay -->
      <v-overlay :value="overlay" z-index="10000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "DocumentManagementInvites",

  props: {
    redirect: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      overlay: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Id", align: "start", value: "id" },
        { text: "Email", value: "email" },
        { text: "Company Owner", value: "company_owner" },
        { text: "Company Id", value: "company_id" },
        { text: "User Id", value: "user_id" },
        { text: "URL", value: "url" },
        { text: "Token", value: "token" },
        { text: "Created At", value: "created_at" },
        // { text: "Updated At", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      invites: [],
      editedIndex: -1,
      editedInvite: {
        id: 0,
        email: "",
        token: "",
        company_owner: false,
        company_id: 0,
        user_id: 0,
        created_at: "",
        updated_at: "",
      },
      defaultInvite: {
        id: 0,
        email: "",
        token: "",
        company_owner: false,
        company_id: 0,
        user_id: 0,
        created_at: "",
        updated_at: "",
      },

      useCompanyName: false,

      // company name
      companyName: "",
      companyNameRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) => v.length >= 3 || "Can not be less than 3 characters",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],

      // email
      emailRules: [
        (v) => !!v || "Required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],

      page: 1,
      invitesPerPage: 100,
      totalRecords: 0,
      pageCount: 0,
      perPageChoice: { text: "100 records/page", value: 100 },
      perPageChoices: [
        { text: "5 records/page", value: 5 },
        { text: "25 records/page", value: 25 },
        { text: "50 records/page", value: 50 },
        { text: "100 records/page", value: 100 },
        { text: "150 records/page", value: 150 },
        { text: "200 records/page", value: 200 },
      ],
    };
  },

  computed: {
    ...mapState({ auth: "auth" }),

    formTitle() {
      return this.editedIndex === -1 ? "New Invite" : "Edit Invite";
    },
  },

  watch: {
    // dialog
    dialog(val) {
      val || this.close();
    },

    // dialogDelete
    dialogDelete(val) {
      val || this.closeDelete();
    },

    // page
    page() {
      this.getApiPagedInvites();
    },

    // invitesPerPage
    invitesPerPage() {
      this.getApiPagedInvites();
    },

    // perPageChoice
    perPageChoice(val) {
      this.invitesPerPage = val.value;
      this.page = 1;
    },
  },

  created() {
    if (!this.$can("invite_access")) {
      this.$router.push("/dashboard/inbox");
    }

    // this.getApiInvites();
    this.getApiPagedInvites();
  },

  methods: {
    ...mapMutations({
      resetAuthState: "auth/resetState",
    }),

    // copyUrl
    copyUrl(invite) {
      let link = process.env.VUE_APP_BASE_URL; // replace with your link

      //
      if(invite.company_owner){
        link += "company-accept-invite?token=" + invite.token;
      } else{
        link += "user-accept-invite?token=" + invite.token;
      }

      navigator.clipboard.writeText(link)
        .then(() => {
          // console.log('Link copied to clipboard');
        })
        .catch(() => {
          // console.error('Error copying link to clipboard:', error);
        });
    },

    // validateForm
    validateForm() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.updateApiInvite();
        } else {
          this.createApiInvite();
        }
      }
    },

    // editInvite
    editInvite(invite) {
      this.editedIndex = this.invites.indexOf(invite);
      this.editedInvite = Object.assign({}, invite);
      this.dialog = true;

      if (this.$can("super_admin")) {
        this.useCompanyName = true;
      }
    },

    // deleteInvite
    deleteInvite(invite) {
      this.editedIndex = this.invites.indexOf(invite);
      this.editedInvite = Object.assign({}, invite);
      this.dialogDelete = true;
    },

    // deleteInviteConfirm
    deleteInviteConfirm() {
      this.deleteApiInvite();
    },

    // close
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedInvite = Object.assign({}, this.defaultInvite);
        this.editedIndex = -1;
      });

      this.companyName = "";

      this.$refs.form.resetValidation();
    },

    // closeDelete
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedInvite = Object.assign({}, this.defaultInvite);
        this.editedIndex = -1;
      });
    },

    // save
    save() {
      this.validateForm();
    },

    // Get Api Invites
    async getApiInvites() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("invites");

      if (res.status == 200) {
        this.invites = res.data;
      }

      this.overlay = false;
    },

    // Get Api Paged Invites
    async getApiPagedInvites() {
      this.overlay = true;

      const res = await this.$helpers.getApiData(
        `invites/paged?page_no=${this.page}&page_size=${this.invitesPerPage}`
      );

      if (res.status == 200) {
        this.invites = res.data.data;
        this.totalRecords = res.data.item_count;
        this.pageCount = res.data.page_count;

        this.$vuetify.goTo(0);
      }

      this.overlay = false;
    },

    // Create Api Invite
    async createApiInvite() {
      this.overlay = true;

      var reqData = {
        email: this.editedInvite.email,
      };

      if (this.useCompanyName) {
        reqData = {
          email: this.editedInvite.email,
          company_name: this.companyName,
        };
      }

      var res = null;

      if (this.useCompanyName) {
        res = await this.$helpers.createApiData(
          "invites/company-invite",
          reqData,
          "Invite Created Successfully!"
        );
      } else {
        res = await this.$helpers.createApiData(
          "invites/user-invite",
          reqData,
          "Invite Created Successfully!"
        );
      }

      if (res.status == 200) {
        this.invites.push(res.data);

        this.close();
      }

      this.overlay = false;
    },

    // Update Api Invite
    async updateApiInvite() {
      this.overlay = true;

      const res = await this.$helpers.updateApiData(
        `invites/${this.editedInvite.id}`,
        {
          id: this.editedInvite.id,
          email: this.editedInvite.email,
        },
        "Invite Updated Successfully!"
      );

      if (res.status == 200) {
        Object.assign(this.invites[this.editedIndex], res.data);

        this.close();
      }

      this.overlay = false;
    },

    // Delete Api Invite
    async deleteApiInvite() {
      this.overlay = true;

      const res = await this.$helpers.deleteApiData(
        `invites/${this.editedInvite.id}`,
        "Invite Deleted Successfully!"
      );

      if (res.status == 200) {
        this.invites.splice(this.editedIndex, 1);

        this.closeDelete();
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
